import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import { uniqBy } from 'lodash'
import React, { memo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Filter, Props as FilterProps } from './Filter'
import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  brand?: string
  description?: string
  filters?: FilterProps[]
  languageCode: string
  offers: OfferProps[]
  title?: string
}

export const OffersList = memo(function OffersList({
  brand,
  description,
  filters,
  languageCode,
  offers,
  title,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')
  const [ref, isVisible] = useInView()
  const variants = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.15,
      },
    },
    hidden: {
      opacity: 0,
    },
  }

  return (
    <Container>
      {brand ? (
        <FadeInUp>
          <Brand style={{ backgroundImage: `url(${brand})` }} />
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      <Wrapper className={isVisible ? 'visible' : undefined} ref={ref}>
        {filters ? (
          <motion.div
            ref={ref}
            animate={isVisible ? 'visible' : 'hidden'}
            variants={variants}
          >
            <Filters dial={2} inline row wrap>
              <Filter
                active={filteredCategory === 'all'}
                category="all"
                dateCreated=""
                label={useVocabularyData('all', languageCode)}
                onClick={() => setFilteredCategory('all')}
              />
              {uniqBy(filters, 'category')
                .sort((a, b) => a.category.localeCompare(b.category))
                .map((item, index) => {
                  const category = item.category
                    ? item.category
                        .replace(/[^a-zA-Z ]/g, '')
                        .toLocaleLowerCase()
                    : undefined

                  return (
                    <Filter
                      {...item}
                      active={filteredCategory === category}
                      dateCreated={item.dateCreated}
                      key={index}
                      onClick={(e) => {
                        setFilteredCategory(
                          e.target.getAttribute('data-category'),
                        )
                      }}
                    />
                  )
                })}
            </Filters>
          </motion.div>
        ) : null}
        <Services row wrap space="between" stretch>
          {offers
            .sort(
              (a, b) =>
                new Date(b.dateCreated).getTime() -
                new Date(a.dateCreated).getTime(),
            )
            .map((item, index) => {
              const category = item.category
                ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
                : undefined
              return (
                <Offer
                  key={index}
                  className={
                    filteredCategory === category || filteredCategory === 'all'
                      ? 'visible'
                      : undefined
                  }
                  {...item}
                />
              )
            })}
        </Services>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.0625rem auto 7.0625rem;
  padding: 0 12.917vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto 5.625rem;
  }
`

const Brand = styled.div`
  width: 4.5rem;
  height: 3.75rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 0.375rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.25rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 2.25rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const Wrapper = styled.div`
  margin-top: 5rem;
  position: relative;
  &:before {
    content: '';
    width: calc(100% - 10.556vw);
    height: 5.875rem;
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    border-bottom: 0;
    position: absolute;
    top: 0.625rem;
    left: 5.278vw;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
    &:before {
      display: none;
    }
  }
`

const Filters = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-bottom: 1.25rem;
  padding: 0 5.278vw;

  @media (max-width: 1023px) {
    margin-bottom: 0;
    padding: 0;
  }
`

const Services = styled(FlexBox)`
  > a {
    width: calc(50% - 1.125rem);
    margin-top: 3rem;
  }

  @media (max-width: 1023px) {
    > a {
      width: 100%;
      margin-top: 2.25rem;
    }
  }
`
