import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  category?: string
  className?: string
  dateCreated: string
  description?: string
  image?: ImageProps
  label?: string
  languageCode: string
  title?: string
  URL: string
}

export const Offer = memo(function Offer({
  category,
  className,
  description,
  image,
  label,
  languageCode,
  title,
  URL,
}: Props) {
  const [ref, isVisible] = useInView()
  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.15,
      },
    },
    hidden: {
      opacity: 0,
      y: '1.5rem',
    },
  }

  return (
    <Container className={className} data-category={category} to={URL}>
      <motion.div
        ref={ref}
        animate={isVisible ? 'visible' : 'hidden'}
        variants={variants}
      >
        <Background className="offer-bg">
          {label ? <Label className="offer-label">{label}</Label> : null}
          {image ? <LazyLoadImage {...image} /> : null}
          {category ? (
            <Category className="offer-category">{category}</Category>
          ) : null}
        </Background>
        <Wrapper>
          {title ? <Title>{title}</Title> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          <CTA
            className="offer-cta"
            label={useVocabularyData('discover-details', languageCode)}
            noActions
            variant="S"
          />
        </Wrapper>
      </motion.div>
    </Container>
  )
})

const Container = styled(Link)`
  display: none;
  &.visible {
    display: block;
  }
  &:hover {
    > div {
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
    }
    .offer-bg {
      img {
        transform: scale(1);
      }
    }
    .offer-label,
    .offer-category {
      opacity: 0;
    }
    .offer-cta {
      color: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }

  > div {
    height: 100%;
    position: relative;
    transition: 0.2s ease-in-out;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 39%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.2s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    transform: scale(1.1);
  }

  @media (max-width: 1023px) {
    padding-bottom: 70%;
  }
`

const Category = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  position: absolute;
  bottom: 2.25rem;
  left: 50%;
  text-transform: uppercase;
  transform: translateX(-50%);
  transition: 0.2s ease-in-out;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin: 0 0.5rem;
    transform: rotate(45deg);
  }
`

const Label = styled.div`
  max-width: 6.667vw;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  padding: 1rem 1.375rem;
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  z-index: 2;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const Wrapper = styled.div`
  padding: 3.333vw;
  text-align: left;

  @media (max-width: 1023px) {
    padding: 3rem 1.9375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
`

const Description = styled.div`
  height: 3.75rem;

  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
  transition: 0.2s ease-in-out;
`
